import React from 'react';
import { LOADER_ICON } from '../../Assets';

type Props = {
  size?: string,
  additionalStyle?: string
}

const Loader = ({ size = '', additionalStyle = '' }: Props) => {
  return (
    <div className={`d-flex justify-content-center ${additionalStyle}`}>
      {
        size ? (
          <div className={`spinner-border ${size ? 'spinner-border-sm' : ''}`} role="status">
            <span className="sr-only"></span>
          </div>
        ) : (
          <img className='animation_one' src={LOADER_ICON} alt="" width={60} height={60} />
        )
      }
    </div>
  )
};

export default Loader;