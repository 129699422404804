import React, { useState } from 'react';
import { CustomInput, Loader } from '../../../Shared/Elements';
import { Form, Row } from '../../../Shared/Bootstrap';
import { ENDPOINTS } from '../../../utility/ApiService';
import { POST } from '../../../utility/http';
import { alertError, alertInfo, alertSuccess } from '../../../utility/toaster';
import { validations } from '../../../utility/validations';
import { useStateValue } from '../../../Context/Context';
import { useParams } from 'react-router-dom';
import { ThemeModel } from '../../../utility/Models';
import { VALIDATION_RANGE } from '../../../utility/constant';

type Props = {
    theme?: string | ThemeModel | null,
    col?: number,
    callBackFunction?: any;
};

const EnquiryForm = ({ theme = null, col = 6, callBackFunction = null }: Props) => {
    const [{ cardTheme, isPreview }] = useStateValue();
    const { id } = useParams();
    const styles: ThemeModel = theme || cardTheme;

    const bg: string = typeof styles === 'string' ? styles : styles?.background;
    const initialData = {
        name: {
            value: '',
            error: '',
            validation: {
                required: true,
                min: VALIDATION_RANGE.minLength,
                max: VALIDATION_RANGE.text   
            }
        },
        mobile: {
            value: '',
            error: '',
            validation: {
                min: VALIDATION_RANGE.mobile,
                max: VALIDATION_RANGE.mobile,
                required: true
            }
        },
        email: {
            value: '',
            error: '',
            validation: {
                email: true,
                max: VALIDATION_RANGE.text
            }
        },
        description: {
            value: '',
            error: '',
            validation: {
                required: true,
                max: VALIDATION_RANGE.longDescription
            }
        }
    }
    const [data, setData] = useState(initialData);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = async (e: any) => {
        const properties: string = e?.target?.name;
        const value: string = e?.target?.value;
        const tempData: any = data;
        const validation: any = tempData[properties].validation;
        const error: any = await validations(properties, value, validation);
        setData((pre: any) => ({
            ...pre,
            [properties]: {
                ...pre[properties],
                value,
                error
            }
        }));
    };

    const validateAll = async () => {
        let isValidData = true;
        let result: any = {};
        const tempData: any = data;
        Object.keys(data).forEach((obj: string) => {
            const error: string = validations(obj, tempData[obj].value, tempData[obj]?.validation);
            if (error) {
                isValidData = false;
                setData((pre: any) => ({
                    ...pre,
                    [obj]: {
                        ...pre[obj],
                        error
                    }
                }));
            }
            result[obj] = tempData[obj]?.value;
        });
        return ({
            isValidData,
            result
        });
    };

    const onSubmit = async () => {
        setIsLoading(true);
        const { isValidData, result } = await validateAll();
        if (isValidData) {
            POST(ENDPOINTS.enquiry.default + `/${id || 'website'}`, result).then((res) => {
                setData(initialData);
                setIsLoading(false);
                alertSuccess('Thanks for enquiring us. We will connect with you shortly');
                if (callBackFunction) callBackFunction();
            }).catch((err) => {
                setIsLoading(false);
                alertError(err);
            })
        } else {
            setIsLoading(false);
            alertInfo('Added invalid data. please check the form.')
        }
    };
    return (
        <Form className='p-0 m-0'>
            <Row className="mb-3 p-3">
                <CustomInput
                    type="text"
                    keyname="name"
                    placeholder="Name"
                    value={data?.name?.value}
                    error={data?.name?.error}
                    onChangeEvent={handleChange}
                    isRequired={true}
                    col={col}
                    isSetValueRequired={true}
                    isSetDefaultValueRequired={false}
                />
                <CustomInput
                    type="email"
                    keyname="email"
                    placeholder="Email"
                    value={data?.email?.value}
                    error={data?.email?.error}
                    onChangeEvent={handleChange}
                    col={col}
                    isSetValueRequired={true}
                    isSetDefaultValueRequired={false}
                />
                <CustomInput
                    type="number"
                    keyname="mobile"
                    placeholder="Mobile"
                    value={data?.mobile?.value}
                    error={data?.mobile?.error}
                    onChangeEvent={handleChange}
                    isRequired={true}
                    col={col}
                    isSetValueRequired={true}
                    isSetDefaultValueRequired={false}
                />
                <CustomInput
                    type="number"
                    keyname="description"
                    placeholder="Description"
                    value={data?.description?.value}
                    error={data?.description?.error}
                    onChangeEvent={handleChange}
                    isRequired={true}
                    isTextarea={true}
                    col={col}
                    isSetValueRequired={true}
                    isSetDefaultValueRequired={false}
                />
                <div className='d-flex align-center justify-content-center mt-3'>
                    <button
                        type='button'
                        className={`${bg} border-0 rounded px-4 btn text-white`}
                        disabled={isPreview}
                        onClick={onSubmit}
                    >
                        {isLoading ? <Loader size={'sm'} /> : 'Submit'}
                    </button>
                </div>
            </Row>
        </Form>
    )
}

export default EnquiryForm;