import React from "react";
import ErrorInfo from "../ErrorInfo";

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { error: "" };
  }

  componentDidCatch(error: any) {
    this.setState({ error: `${error.name}: ${error.message}` });
  }

  render() {
    const { error }: any = this.state;
    const { children }: any = this.props;
    if (error) {
      return (
        <div><ErrorInfo message={error} /></div>
      );
    } else {
      return <>{children}</>;
    }
  }
}

export default ErrorBoundary;