import { ACTION_TYPE, INITIAL_STATE } from './action';

export const reducer = (state: any, action: any) => {
    switch (action?.type) {
        case ACTION_TYPE.APP_LOGOUT:
            return {
                ...state,
                app: {
                    ...state?.app,
                    valid: false,
                    data: {}
                }
            };
        case ACTION_TYPE.APP_GET_INFO_LOADING:
            return {
                ...state,
                app: {
                    ...state?.app,
                    loading: true
                }
            };
        case ACTION_TYPE.APP_GET_INFO_SUCCESS:
            return {
                ...state,
                app: {
                    ...state?.app,
                    loading: false,
                    error: false,
                    valid: true,
                    data: action?.payload
                }
            };
        case ACTION_TYPE.APP_GET_INFO_NOAUTH_SUCCESS:
            return {
                ...state,
                app: {
                    ...state?.app,
                    loading: false,
                    error: false,
                    valid: false,
                    data: {}
                }
            };
        case ACTION_TYPE.APP_GET_INFO_FAILURE:
            return {
                ...state,
                app: {
                    ...state?.app,
                    loading: false,
                    error: true,
                    valid: false,
                    data: {}
                }
            };
        case ACTION_TYPE.CARD_THEME_UPDATE:
            return {
                ...state,
                cardTheme: action?.payload
            }
        case ACTION_TYPE.MODIFY_ISPREVIEW_STATUS:
            return {
                ...state,
                isPreview: action?.isPreview
            }
        default:
            return INITIAL_STATE;

    }

}