import { Fragment } from "react";
import { DEFAULT_BACKGROUND } from "../../../utility/constant";
import { Button, Modal } from "../../Bootstrap";

type Props = {
  viewList: Array<any>,
  showViewModel: boolean,
  handleViewModel: any,
  title: string
};

const ViewData = ({ viewList = [], showViewModel, handleViewModel, title = 'View' }: Props) => {
  return (
    <Modal show={showViewModel} onHide={handleViewModel} size="lg" centered>
      <Modal.Header className={`${DEFAULT_BACKGROUND} text-white-50`} closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {viewList?.length > 0 ? (
          <table className="table">
            <tbody>
              {viewList.map((obj: any, i: number) => {
                return (
                  <Fragment key={'table' + i}>
                    <tr key={'info' + i}>
                      <td className="align-top view-data-table">
                        <label className='text-capitalize text-break'>
                          <b>{obj?.name}</b>
                        </label>
                      </td>
                      <td className="align-top pr-2">:</td>
                      <td className="pb-2 text-break align-top">&nbsp;&nbsp;{obj?.value}</td>
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          </table>
        ) : <label>No data</label>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleViewModel}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
};

export default ViewData;
