import React from 'react';
import { loadAOSAnimation } from '../../../utility/common';
import { AOS_ANIMATIONS } from '../../../utility/constant';
import { EMPTY_ICON } from '../../Assets';

type Props = {
    message?: string
};

const EmptyData = ({ message = 'No data found' }: Props) => {
    return (
        <div className="empty-state" {...loadAOSAnimation(AOS_ANIMATIONS.fadeUp, { timeDelay: 300 })}>
            <div className="empty-state__content" >
                <div className="empty-state__icon">
                    <img src={EMPTY_ICON} alt="" />
                </div>
                <div className="empty-state__message">{message}</div>
                <div className="empty-state__help">
                    What you are looking not here. Create new record.
                </div>
            </div>
        </div>
    )
};

export default EmptyData;
