import { isValidData } from "./common";

const validations = (properties: string, value: any, validation: any, text: string = '') => {
    let error = "";
    if (validation) {
        let title = text ? text : properties.replace('_', ' ');
        title = title[0].toUpperCase() + title.slice(1).toLowerCase();
        if (validation?.required) {
            if (!value) {
                error = `${title} can't be empty`;
                return error;
            }
        }
        if (validation?.noSpecialCharectors) {
            const validChars = /^[a-z0-9$\-_.+!*]*$/;
            if (!validChars.test(value)) {
                error = `${title} should contain only a-z, 0-9 and $-_.+!*`;
                return error;
            } 
        }
        if (validation?.passwordPattern || validation?.passwordPatternLogin) {
            if (!/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value)) {
                if (validation?.passwordPatternLogin) {
                    return error = `Please provide valid ${title}`;
                }
                error = `${title} should contain symbol, number and upper case.`;
                if (value?.length < 8) error = `${title} should contain minimum 8 character.`;
                return error;
            }
        }
        if (!value) return error;
        if (validation?.min) {
            if (value.length < validation?.min) {
                error = `${title} should not be smaller than ${validation?.min} characters`;
                return error;
            }
        }
        if (validation?.limit) {
            if(typeof value === 'string') value = parseInt(value);
            if (value > validation?.limit) {
                error = `${title} should not be smaller than ${validation?.limit} characters`;
                return error;
            }
        }
        if (validation?.max) {
            if (value.length > validation?.max) {
                error = `${title} should not be greater than ${validation?.max} characters`;
                return error;
            }
        }
        if (validation?.email) {
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (!value.match(validRegex)) {
                error = `${title} should not be valid`;
                return error;
            }
        }
        if (validation?.link) {
            let pattern: any = '';
            if (properties === "facebook")
                pattern = /^(?:(?:http|https):\/\/)?(?:www\.)?(?:facebook|fb|m\.facebook)\.(?:com|me)/;
            else if (properties === "telegram")
                pattern = /^(?:(?:http|https):\/\/)?(?:www\.)?t.me\.com/;
            else if (properties === "youtube" || properties === "profile_video")
                pattern = /^(?:(?:http|https):\/\/)?(?:www\.)?youtube\.com/;
            else if (properties === "twitter")
                pattern = /^(?:(?:http|https):\/\/)?(?:www\.)?twitter\.com/;
            else if (properties === "skype")
                pattern = /^(?:(?:http|https):\/\/)?(?:www\.)?join.skype\.com/;
            else if (properties === "threads")
                pattern = /^(?:(?:http|https):\/\/)?(?:www\.)?threads\.com/;
            else if (properties === "instagram")
                pattern = /^(?:(?:http|https):\/\/)?(?:www\.)?instagram\.com/;
            else if (properties === "linkedin")
                pattern = /^(?:(?:http|https):\/\/)?(?:www\.)?linkedin\.com/;
            else if (properties === "snapchat")
                pattern = /^(?:(?:http|https):\/\/)?(?:www\.)?snapchat\.com/;
            else {
                pattern = new RegExp('^(https?:\\/\\/)?' +
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
                    '((\\d{1,3}\\.){3}\\d{1,3}))' +
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*' +
                    '(\\?[;&a-z\\d%_.~+=-@]*)?' +
                    '(\\#[-a-z\\d_]*)?$', 'i');
            }
            if (value && !pattern.test(value)) {
                error = `Please enter valid ${properties} link`;
                return error;
            }
        }
    }
    return error;
};

const checkDataAvailability = (data: any) => {
    let isValid = false;
    if (
        data?.value ||
        data?.link ||
        data?.image ||
        data?.product ||
        data?.description ||
        (data?.progress && data?.progress?.length !== 0) ||
        (data?.list && data?.list?.length !== 0) ||
        isValidData(data?.icon_list, 'link') ||
        isValidData(data?.table) ||
        isValidData(data?.link_list) ||
        data?.video ||
        data?.enquiry
    ) isValid = true;
    return isValid;
};

const checkHeaderAvailability = (data: any) => {
    let isValid = false;
    data?.forEach((obj: any) => {
        if (checkDataAvailability(obj)) isValid = true;
    });
    return isValid;
};

export {
    validations,
    checkDataAvailability,
    checkHeaderAvailability
};
