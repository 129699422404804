import TooltipWrapper from "../Tooltip";

type Props = {
  id: string,
  message: string,
  element?: any,
  placement?: any,
  maxText?: number
}

const EllipsisText = (props: Props) => {
  const { id, message, maxText = 128 } = props;
  if (message?.length < maxText) {
    return <span id={id}>{message}</span>
  }
  const toolTipText = message?.substring(0, maxText) + "...";
  return (
    <TooltipWrapper {...props} element={<span>{toolTipText}</span>} />
  )
};

export default EllipsisText;
