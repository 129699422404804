import { useNavigate } from "react-router-dom";
import { LOGO } from "../../Assets";
import { Container, Nav, Navbar, Image } from "../../Bootstrap";
import {  ROUTES } from "../../../utility/constant";
import ProfileNavDropdown from "./dropdown";

function Header() {
    const navigate = useNavigate();
    const navigateToWebsite = () => navigate(ROUTES.website);

    return (
        <Navbar bg="light" data-bs-theme="light" fixed="top" className="box_shadow p-0">
            <Container>
                <h6 className="my-3" onClick={navigateToWebsite} >
                    <Image height={35} src={LOGO} />
                </h6>
                <Nav className="nav navbar-nav navbar-right profile-dropdown">
                    <ProfileNavDropdown />
                </Nav>
            </Container>
        </Navbar>
    );
}

export default Header;