import { toast } from "react-toastify";
import { clearLocalStorageData, clearSessionStorageData } from "./storage";
import { ROUTES } from "./constant";

const alertSuccess = (value: any) => {
  toast.success(value, { autoClose: 2000 });
};

const alertInfo = (value: any) => {
  toast.info(value, { autoClose: 2000 });
};

const autherizationErrorCode = {
  FORBIDDEN: 403,
  UNAUTHERIZED: 401
}

const errorCode = {
  ...autherizationErrorCode,
  SECURITY_ERROR: 412,
  TOO_MANY_REQUEST: 429
}

const alertError = (value: any) => {
  let errorMessage = (value && typeof value === 'string') || value?.response?.message || value?.response?.data?.message || 'Error Response';
  if (Object.values(errorCode)?.includes(value.response?.status)) {
    clearSessionStorageData();
    clearLocalStorageData();
    errorMessage = 'Session expired. Please login again.'
    window.location.replace(ROUTES.loading);
  }
  if (value.response?.status != 403 || value.response?.status == 401) toast.error(errorMessage);
};

const alertNotAuthendicated = (value: any) => {
  if (Object.values(autherizationErrorCode)?.includes(value.response?.status)) {
    clearSessionStorageData();
    clearLocalStorageData();
    window.location.replace(ROUTES.loading);
  }
};

export {
  alertError,
  alertSuccess,
  alertInfo,
  alertNotAuthendicated
}
