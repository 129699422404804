import React, { Fragment, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from './Routes';
import { GET } from './utility/http';
import { ENDPOINTS } from './utility/ApiService';
import { Loader, TitleUpdate } from './Shared/Elements';
import { useStateValue } from './Context/Context';
import { ACTION_TYPE } from './Context/action';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { APP_VERSION, STORAGE_KEY } from './utility/constant';
import { clearLocalStorageData, getLocalStorageData, setLocalStorageData } from './utility/storage';

function App() {
  const [{ app, cardTheme }, dispatch] = useStateValue();

  useEffect(() => {
    AOS.init();
    checkAppVersion();
    getAppinfo();    
  }, []);

  const checkAppVersion = async () => {
    let data: any = await getLocalStorageData(STORAGE_KEY.versionKey);
    if(data !== APP_VERSION) {
      await clearLocalStorageData();
      await setLocalStorageData(STORAGE_KEY.versionKey, APP_VERSION);
    }
    return;
  }

  // const path = window.location.hash;
  // const isNoAuthScreen = path.includes('login');

  const getAppinfo = async () => {
    await checkAppVersion;
    let data: any = await getLocalStorageData(STORAGE_KEY.auth);
    if (!data) {
      dispatch({
        type: ACTION_TYPE.APP_GET_INFO_NOAUTH_SUCCESS,
        payload: {}
      })
      return;
    };
    dispatch({ type: ACTION_TYPE.APP_GET_INFO_LOADING })
    GET(ENDPOINTS.user.getAuthInfo).then((res) => {
      dispatch({
        type: ACTION_TYPE.APP_GET_INFO_SUCCESS,
        payload: res?.data?.data
      })
    }).catch(() => {
      dispatch({
        type: ACTION_TYPE.APP_GET_INFO_FAILURE,
        payload: {}
      })
    });
  };

  return (
    <Fragment>
      <TitleUpdate />
      {
        app?.loading ? (<Loader additionalStyle={`app-loader`} />) : <AppRoutes />
      }
      <ToastContainer
        hideProgressBar={true}
        position="top-right"
        autoClose={3000} />
        <style>
            {`
            .bg-custom {
                background: ${cardTheme?.background} !important;
           }
        `}
          </style>
    </Fragment>
  );
}

export default App;
