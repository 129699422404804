import React, { Fragment, useState } from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share';
import { useStateValue } from '../../../Context/Context';
import { capitalizeFirstLetter } from '../../../utility/common';
import { APP_NAME, ROUTES } from '../../../utility/constant';
import { Button, Modal } from '../../Bootstrap';
import { THEMES_BACKGROUND } from '../../../utility/ThemeConstants';
import { alertSuccess } from '../../../utility/toaster';
import Footer from '../Footer';

type Props = {
  name: string
}
const ShareCard = ({ name = '' }: Props) => {
  const [{ cardTheme, isPreview }] = useStateValue();
  const [show, setShow] = useState(false);
  const handleModel = () => setShow(pre => !pre);
  const shareUrl: string = window.location.href;
  const title: string = `${capitalizeFirstLetter(name)} Shared profile card with you from ${APP_NAME}.Please click on the link to see access.`;
  const iconSize: number = 35;
  const ShareButton = [
    {
      Button: WhatsappShareButton,
      Icon: WhatsappIcon
    },
    {
      Button: FacebookShareButton,
      Icon: FacebookIcon
    },
    {
      Button: TelegramShareButton,
      Icon: TelegramIcon
    },
    {
      Button: TwitterShareButton,
      Icon: TwitterIcon
    },
    {
      Button: EmailShareButton,
      Icon: EmailIcon
    },
    {
      Button: LinkedinShareButton,
      Icon: LinkedinIcon
    }
  ]

  const handleCopy = async (value: string) => {
    try {
      await navigator?.clipboard?.writeText(value);
      alertSuccess("Copied successfully!!");
    } catch {
      console.log('Error on copy url !!!');
    }
  };

  const isPredefinedColor = THEMES_BACKGROUND.find((obj) => obj?.value === cardTheme?.background);
  const bg_color: string = isPredefinedColor ? cardTheme?.background : 'bg-custom';
  return (
    <Fragment>
      {!isPreview ? (
        <div className='share_container'>
          <Button className={`p-1 ${bg_color}`} onClick={handleModel}><i className='bi bi-share' /></Button>
          <Modal show={show} onHide={handleModel} size="lg" centered>
            <Modal.Body>
              <div className='d-flex justify-content-between'>
                <h6 className='p-2'><b>Share</b></h6>
                <button className='btn p-0' onClick={handleModel}>
                  <i className='bi bi-x-lg' />
                </button>
              </div>
              <hr className='mt-0' />
              <div className={`m-0`}>
                <Button
                  key='copy'
                  onClick={() => handleCopy(shareUrl)}
                  className="mx-1 mb-2 rounded-5 bg-body-secondary text-black"
                >
                  <i className='bi bi-copy' />
                </Button>
                {
                  ShareButton.map(({ Button, Icon }, i) => (
                    <Button
                      key={'share_me' + i}
                      title={title}
                      url={shareUrl}
                      className="mx-1 mb-2"
                      disabled={isPreview}
                    >
                      <Icon size={iconSize} round={true} />
                    </Button>
                  ))
                }
              </div>
              <div className='mt-4'>
                To have your own <b>LINK/PROFILE</b> card, <a href={window.location.origin + ROUTES.website} className='text-decoration-none'>Click here</a> 
              </div> 
            </Modal.Body>
            <Footer background={bg_color} additionalClass='rounded-bottom-2' />
          </Modal>
        </div>
      ) : null}
    </Fragment>
  )
};

export default ShareCard;