import { DEFAULT_BACKGROUND } from "../../../utility/constant";

type Props = {
  onSearch: any
}

const SearchInput = ({ onSearch }: Props) => (
  <div className="search-input form-group">
    <input type="text" className="form-control" name="search" id="search" placeholder="search" onChange={onSearch} />
    <i className={`bi bi-search ${DEFAULT_BACKGROUND}`}></i>
  </div>
);

export default SearchInput;
