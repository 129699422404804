import { DEFAULT_BACKGROUND } from '../../../utility/constant';
import { EMPTY_ICON, LOGO } from '../../Assets';

type Props = {
  message?: string
};

const ErrorInfo = ({ message = 'Sorry, Something went wrong!!!' }: Props) => {
  return (
    <div className="empty-state">
      <div className="empty-state__content" >
        <img className='my-2 mb-2' width={120} height={40} src={LOGO} alt='' />
        <div className="empty-state__icon">
          <img src={EMPTY_ICON} alt="" />
        </div>
        <button type='button' onClick={() => window.location.reload()} className={`${DEFAULT_BACKGROUND} border-0 rounded px-4 btn text-white my-4`}>
          <i className='bi bi-arrow-clockwise' /> Reload Page</button>
      </div>
    </div>
  )
};

export default ErrorInfo;
