import React from 'react';
import ReactDOM from 'react-dom/client';
import "./styles/index.scss";
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { StateProvider } from './Context/Context';
import ErrorBoundary from './Shared/Elements/ErrorBoundry';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StateProvider>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </StateProvider>
);

reportWebVitals();
