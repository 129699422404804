import { Fragment } from "react";
import { DEFAULT_BACKGROUND } from "../../../utility/constant";
import { getPainationLimit } from "../../../utility/common";

type Props = {
  page: number;
  handlePagination: (type?: boolean) => void;
  actualData: Array<any>;
  totalData: number
};
const Pagintion = ({ page, handlePagination, actualData, totalData }: Props) => {
  const PAGINATION_LIMIT = getPainationLimit();
  return (
    <Fragment>
      {
        totalData > PAGINATION_LIMIT ? (
          <div className='d-flex mt-2'>
            <button
              type="button"
              disabled={page === 0}
              className={`${DEFAULT_BACKGROUND} border-0 mx-1 icon-button-alligment btn text-white`}
              onClick={() => handlePagination(false)}>
              <i className='bi bi-arrow-left' />
            </button>
            <span className='p-1'>{page + 1}</span>
            <button
              type="button"
              disabled={(page + 1) * PAGINATION_LIMIT >= actualData.length}
              className={`${DEFAULT_BACKGROUND} border-0 mx-1 icon-button-alligment btn text-white`}
              onClick={() => handlePagination()}>
              <i className='bi bi-arrow-right' />
            </button>
          </div>
        ) : null
      }
    </Fragment>
  )
}

export default Pagintion;