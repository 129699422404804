import { useNavigate } from "react-router-dom";
import { Dropdown, Image } from "../../Bootstrap";
import { APP_VERSION, ROUTES, STORAGE_KEY } from "../../../utility/constant";
import { useStateValue } from "../../../Context/Context";
import { ACTION_TYPE } from "../../../Context/action";
import { clearLocalStorageData, clearSessionStorageData, getLocalStorageData, setLocalStorageData } from "../../../utility/storage";
import { useEffect, useState } from "react";
import { PROFILE_ICON } from "../../Assets";

function ProfileNavDropdown() {
  const initialData = {
    userId: '',
    username: '',
    admin: false
  };
  const navigate = useNavigate();
  const [data, setData] = useState(initialData);
  const [{ }, dispatch] = useStateValue();

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    let value: any = await getLocalStorageData(STORAGE_KEY.auth);
    if (value) {
      value = JSON.parse(value);
      setData(value);
    }
  }

  const logout = async () => {
    clearSessionStorageData();
    clearLocalStorageData();
    await setLocalStorageData(STORAGE_KEY.versionKey, APP_VERSION);
    dispatch({ type: ACTION_TYPE.APP_LOGOUT });
    navigate(ROUTES.loading);
  };

  const resetPassword = () => navigate(ROUTES.resetPassword);
  const serviceDesk = () => navigate(ROUTES.serviceDesk);
  const serviceList = () => navigate(ROUTES.serviceDeskList);

  return (
    <Dropdown align='end' className="tiny_icon">
      <Dropdown.Toggle variant="none">
        <Image width={30} height={30} src={PROFILE_ICON} roundedCircle />
      </Dropdown.Toggle>
      <Dropdown.Menu className="profile-dropdown">
        <Dropdown.Item disabled className="font-size-one">
          {data?.username} ( {data?.userId} )
        </Dropdown.Item>
        {
          !data?.admin && (
            <Dropdown.Item className="font-size-one" onClick={serviceDesk}>
              Service Desk
            </Dropdown.Item>
          )
        }
        {
          data?.admin && (
            <Dropdown.Item className="font-size-one" onClick={serviceList}>
              Service Queries
            </Dropdown.Item>
          )
        }
        <Dropdown.Item className="font-size-one" onClick={resetPassword}>
          Reset Password
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item className="font-size-one" onClick={logout}>
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ProfileNavDropdown;