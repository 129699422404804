import { STYLES } from "../utility/constant";

export const INITIAL_STATE = {
    app: {
        loading: true,
        error: false,
        valid: false,
        data: {}
    },
    cardTheme: STYLES.background,
    isPreview: false
}

export const ACTION_TYPE = {
    APP_LOGOUT: 'APP_LOGOUT',
    APP_GET_INFO_LOADING: 'APP_GET_INFO_LOADING',
    APP_GET_INFO_SUCCESS: 'APP_GET_INFO_SUCCESS',
    APP_GET_INFO_NOAUTH_SUCCESS: 'APP_GET_INFO_NOAUTH_SUCCESS',
    APP_GET_INFO_FAILURE: 'APP_GET_INFO_FAILURE',
    CARD_THEME_UPDATE: 'CARD_THEME_UPDATE',
    MODIFY_ISPREVIEW_STATUS: 'MODIFY_ISPREVIEW_STATUS'
};