import axios from "axios";
import { ENDPOINTS } from "./ApiService";
import { ROUTES, STORAGE_KEY } from "./constant";
import {
  clearLocalStorageData,
  clearSessionStorageData,
  getLocalStorageData,
  getSessionStorageData,
  setLocalStorageData
} from "./storage";
import { getUTCTimestamp } from "./common";

const localUrl = "https://backend.krusho.com";

export const customFetch = axios.create({
  baseURL: localUrl,
  headers: {
    "Content-type": "application/json",
    "requesttime": getUTCTimestamp(),
    timeout: 1000 * 30, // Wait for 5 seconds
  },
  // withCredentials: true,
});

const getToken = async () => {
  let data: any = await getLocalStorageData(STORAGE_KEY.auth);
  if (data) {
    data = JSON.parse(data);
    let user_id = '';
    if (data?.admin || data?.orgAdmin) {
      let user: any = await getSessionStorageData(STORAGE_KEY.selectedUserInfo);
      if (user) {
        user = JSON.parse(user);
        user_id = user?._id
      }
    }
    return {
      token: data?.token,
      refreshToken: data?.refreshToken,
      user_id,
      deviceId: data?.deviceId
    }
  } else {
    return {};
  }
};

const setToken = (data: any) => {
  if (data) {
    setLocalStorageData(STORAGE_KEY.auth, JSON.stringify(data));
  }
};

customFetch.interceptors.request.use(
  async (config) => {
    const { token, user_id, deviceId } = await getToken();
    if (deviceId) {
      config.headers["x-device-id"] = deviceId;
    }
    if (token) {
      config.headers["authorization"] = `bearer ${token}`;
    }
    if (user_id) {
      config.headers["userid"] = user_id;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const refreshToken = async () => {
  try {
    const { refreshToken } = await getToken();
    const resp = await customFetch.get(ENDPOINTS.auth.refreshToken + '/' + refreshToken);
    return resp.data;
  } catch (e) {
    console.log("Error", e);
  }
};

customFetch.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const resp = await refreshToken();
      const data: any = resp.data;
      setToken(data);
      customFetch.defaults.headers.common["authorization"] = `Bearer ${data?.token}`;
      return customFetch(originalRequest);
    }
    if (error.response.status === 412) {
      clearSessionStorageData();
      clearLocalStorageData();
      window.location.replace(ROUTES.loading);
    }
    return Promise.reject(error);
  }
);