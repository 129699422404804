import { PhoneCall, MessageDetail, MailSend, MessageAdd, Briefcase, Globe, Home } from '@styled-icons/boxicons-regular';
import { Whatsapp, Skype, Telegram, Facebook, Instagram, Twitter, Youtube, Linkedin, Snapchat, Messenger, Google } from '@styled-icons/boxicons-logos';
import { CallAdd, MailAdd, Payment, DesignIdeas } from '@styled-icons/fluentui-system-regular';
import { Download, Location, User } from '@styled-icons/entypo';
import { Profile, FileUser } from '@styled-icons/remix-line';
import { Project } from '@styled-icons/octicons';
import { UserGraduate, UserGear, Link, Crown } from '@styled-icons/fa-solid';
import { Office, Qrcode } from '@styled-icons/icomoon';
import { MiscellaneousServices, ProductionQuantityLimits, Security, NotificationAdd } from '@styled-icons/material';
import { FiletypePdf, ArrowRightCircle } from '@styled-icons/bootstrap';
import { Phonepe, Googlepay } from '@styled-icons/simple-icons';
// https://styled-icons.dev/?s=call

enum ICON_TYPE {
    call = 'call',
    callAdd = 'callAdd',
    message = 'message',
    messageAdd = 'messageAdd',
    mail = 'mail',
    mailAdd = 'mailAdd',
    whatsapp = 'whatsapp',
    skype = 'skype',
    telegram = 'telegram',
    download = 'Download',
    profile = 'profile',
    education = 'education',
    project = 'project',
    resume = 'resume',
    skill = 'skill',
    experiance = 'experiance',
    office = 'office',
    service = 'service',
    website = 'website',
    location = 'location',
    facebook = 'facebook',
    youtube = 'youtube',
    twitter = 'twitter',
    instagram = 'instagram',
    linkedin = 'linkedin',
    snapchat = 'snapchat',
    link = 'link',
    product = 'product',
    document = 'document',
    qrcode = 'qrcode',
    phonepe = 'phonepe',
    gpay = 'gpay',
    arrow = 'arrow',
    crown = 'crown',
    home = 'home',
    payment = 'payment',
    theme = 'theme',
    security = 'security',
    messenger = 'messenger',
    user = 'user',
    notification = 'notification',
    google = 'google'
}

const IconWrapper = ({ type }: { type?: string }) => {
    let Wrapper: any = Link;
    switch (type) {
        case ICON_TYPE.call:
            Wrapper = PhoneCall;
            break;
        case ICON_TYPE.callAdd:
            Wrapper = CallAdd;
            break;
        case ICON_TYPE.message:
            Wrapper = MessageDetail;
            break;
        case ICON_TYPE.messageAdd:
            Wrapper = MessageAdd;
            break;
        case ICON_TYPE.whatsapp:
            Wrapper = Whatsapp;
            break;
        case ICON_TYPE.mail:
            Wrapper = MailSend;
            break;
        case ICON_TYPE.mailAdd:
            Wrapper = MailAdd;
            break;
        case ICON_TYPE.skype:
            Wrapper = Skype;
            break;
        case ICON_TYPE.telegram:
            Wrapper = Telegram;
            break;
        case ICON_TYPE.download:
            Wrapper = Download;
            break;
        case ICON_TYPE.profile:
            Wrapper = Profile;
            break;
        case ICON_TYPE.education:
            Wrapper = UserGraduate;
            break;
        case ICON_TYPE.project:
            Wrapper = Project;
            break;
        case ICON_TYPE.resume:
            Wrapper = FileUser;
            break;
        case ICON_TYPE.skill:
            Wrapper = UserGear;
            break;
        case ICON_TYPE.experiance:
            Wrapper = Briefcase;
            break;
        case ICON_TYPE.office:
            Wrapper = Office;
            break;
        case ICON_TYPE.service:
            Wrapper = MiscellaneousServices;
            break;
        case ICON_TYPE.website:
            Wrapper = Globe;
            break;
        case ICON_TYPE.location:
            Wrapper = Location;
            break;
        case ICON_TYPE.facebook:
            Wrapper = Facebook;
            break;
        case ICON_TYPE.instagram:
            Wrapper = Instagram;
            break;
        case ICON_TYPE.youtube:
            Wrapper = Youtube;
            break;
        case ICON_TYPE.twitter:
            Wrapper = Twitter;
            break;
        case ICON_TYPE.linkedin:
            Wrapper = Linkedin;
            break;
        case ICON_TYPE.snapchat:
            Wrapper = Snapchat;
            break;
        case ICON_TYPE.link:
            Wrapper = Link;
            break;
        case ICON_TYPE.product:
            Wrapper = ProductionQuantityLimits;
            break;
        case ICON_TYPE.document:
            Wrapper = FiletypePdf;
            break;
        case ICON_TYPE.qrcode:
            Wrapper = Qrcode;
            break;
        case ICON_TYPE.phonepe:
            Wrapper = Phonepe;
            break;
        case ICON_TYPE.gpay:
            Wrapper = Googlepay;
            break;
        case ICON_TYPE.arrow:
            Wrapper = ArrowRightCircle;
            break;
        case ICON_TYPE.crown:
            Wrapper = Crown;
            break;
        case ICON_TYPE.home:
            Wrapper = Home;
            break;
        case ICON_TYPE.payment:
            Wrapper = Payment;
            break;
        case ICON_TYPE.theme:
            Wrapper = DesignIdeas;
            break;
        case ICON_TYPE.security:
            Wrapper = Security;
            break;
        case ICON_TYPE.messenger:
            Wrapper = Messenger;
            break;
        case ICON_TYPE.user:
            Wrapper = User;
            break;
        case ICON_TYPE.notification:
            Wrapper = NotificationAdd;
            break;
        case ICON_TYPE.google:
            Wrapper = Google;
            break;
    }

    return <Wrapper />
};

export {
    IconWrapper,
    ICON_TYPE
};
