import { Fragment } from "react";
import { DEFAULT_BACKGROUND } from "../../../utility/constant";
import { alertInfo } from "../../../utility/toaster";
import Compressor from 'compressorjs';
import { RESUME_IMAGE } from "../../Assets";

type Props = {
    value: string,
    handleImageChange: any,
    handleRemoveImage: any,
    property?: string,
    listIndex?: string,
    title?: string,
    acceptedType?: string,
    additionalText?: string,
    type?: string
}

const UploadImage = ({
    value,
    handleImageChange,
    handleRemoveImage,
    property,
    listIndex,
    title = 'Profile Image',
    type = 'image',
}: Props) => {
    const inputKey: string | undefined = listIndex ? listIndex : property;
    const isImage = (type === 'image');

    const handleUpload = (event: any) => {
        let file = event.target.files[0];
        if (file.size > 6e6) {
            alertInfo(`Please upload a file smaller than 6 MB.uploaded image size is ${file.size / 1e6}`);
            return false;
        }
        new Compressor(file, {
            quality: 0.6,
            success: (compressedResult) => {
                handleImageChange(compressedResult, inputKey);
            },
        });
    };

    const handleDocumentAdd = async (event: any) => {
        const file = event.target.files;
        if (file) {
            if (file[0]?.size > 10e6) {
                alertInfo('Please upload a file smaller than 10 MB');
            }
            handleImageChange(file[0], inputKey);
        }
    };

    const UPLOAD_DATAS = {
        type: isImage ? 'image/png, image/jpeg' : 'application/pdf',
        additionalText: isImage ? '(png and jpeg)' : '(accepts only pdf file)',
        event: isImage ? handleUpload : handleDocumentAdd
    };

    return (
        <Fragment>
            {value ? (
                <div className='mb-2'>
                    <div className={` text-center p-1 `}><b>{title}</b></div>
                    <img {...isImage && {crossOrigin:"anonymous"}} loading="lazy" width={230} height={230} className={"img-thumbnail"} src={isImage ? value : RESUME_IMAGE} alt={""} />
                    <div
                        className={`${DEFAULT_BACKGROUND} text-center cursor-pointer 
                            rounded-bottom-5 p-1 text-white-50`}
                        onClick={() => handleRemoveImage(inputKey)}>
                        <i className={"bi bi-trash m-2"} ></i> Remove
                    </div>
                </div>
            ) : (
                <Fragment>
                    <input
                        name={property}
                        type="file"
                        id={`${property}-btn`}
                        onChange={UPLOAD_DATAS.event}
                        accept={UPLOAD_DATAS.type} hidden />
                    <label htmlFor={`${property}-btn`} className='upload-file-wrapper pt-5'>
                        <i className={"bi bi-upload h4"} ></i>
                        <div>Upload {title}</div>
                        <span className='text-muted'>{UPLOAD_DATAS.additionalText}</span>
                    </label>
                </Fragment>
            )}
        </Fragment>
    )
};

export default UploadImage;
