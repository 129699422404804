import { customFetch } from "./axios";

const GET = async (url: string) => await customFetch.get(url);
const POST = async (url: string, data: any) =>  await customFetch.post(url, data);
const PUT = async (url: string, data: any) => await customFetch.put(url, data);
const DELETE = async (url: string) => await customFetch.delete(url);

export {
    GET,
    POST,
    PUT,
    DELETE
}
