import { OverlayTrigger, Tooltip } from "../../Bootstrap";

type Props = {
    id: string,
    message: string,
    element?: any,
    placement?: any
}

const TooltipWrapper = ({ id, message, element, placement = 'bottom' }: Props) => (
    <OverlayTrigger placement={placement} overlay={<Tooltip id={id}>{message}</Tooltip>}>
        {element ? (element) :
            <span className='mx-2'><i className='bi bi-info-circle' /></span>
        }
    </OverlayTrigger>
);

export default TooltipWrapper;
