import React, { createContext, useContext, useReducer } from "react";
import { INITIAL_STATE } from './action';

import { reducer } from './reducer';

export const StateContext = createContext<any>({});

export const StateProvider = ({ children }: any) => (
    <StateContext.Provider value={useReducer(reducer, INITIAL_STATE)} >
        {children}
    </StateContext.Provider>
);

export const useStateValue = () => useContext(StateContext);
