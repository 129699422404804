
/**
 * @key type defines the type od color which used to make tab in create/ modify form
 */
export const THEMES_BACKGROUND = Object.freeze([
    {
        value: 'background_one',
        type: 1
    },
    {
        value: 'background_two',
        type: 1
    },
    {
        value: 'background_three',
        type: 1
    },
    {
        value: 'background_four',
        type: 1
    },
    {
        value: 'background_five',
        type: 1
    },
    {
        value: 'background_six',
        type: 1
    },
    {
        value: 'background_seven',
        type: 1
    },
    {
        value: 'background_eight',
        type: 1
    },
    {
        value: 'background_nine',
        type: 1
    },
    {
        value: 'background_ten',
        type: 1
    },
    {
        value: 'background_eleven',
        isAppBackground: true,
        type: 1
    },
    {
        value: 'background_twelve',
        type: 1
    },
    {
        value: 'background_thirteen',
        type: 1
    },
    {
        value: 'background_fourteen',
        type: 1
    },
    {
        value: 'background_fifteen',
        type: 1
    },
    {
        value: 'background_sixteen',
        type: 1
    },
    {
        value: 'background_seventeen',
        type: 1
    },
    {
        value: 'background_eighteen',
        type: 1
    },
    {
        value: 'background_ninteen',
        type: 1
    },
    {
        value: 'background_twenty',
        type: 1
    },
    {
        value: 'background_grad_one',
        type: 2
    },
    {
        value: 'background_grad_two',
        type: 2
    },
    {
        value: 'background_grad_three',
        type: 2
    },
    {
        value: 'background_grad_six',
        type: 2
    },
    {
        value: 'background_grad_eight',
        type: 2
    },
    {
        value: 'background_grad_ten',
        isAppBackground: true,
        type: 2
    },
    {
        value: 'background_grad_eleven',
        type: 2
    },
    {
        value: 'background_grad_four',
        premium: true,
        isAppBackground: true,
        type: 2
    },
    {
        value: 'background_grad_five',
        premium: true,
        isAppBackground: true,
        type: 2
    },
    {
        value: 'background_grad_seven',
        premium: true,
        type: 2
    },

    {
        value: 'background_grad_nine',
        premium: true,
        isAppBackground: true,
        type: 2
    },
    {
        value: 'background_grad_twelve',
        premium: true,
        isAppBackground: true,
        type: 2
    },
    {
        value: 'background_grad_thirteen',
        premium: true,
        isAppBackground: true,
        type: 2
    },
    {
        value: 'background_striped_eight',
        type: 3
    },
    {
        value: 'background_striped_one',
        premium: true,
        type: 3
    },
    {
        value: 'background_striped_two',
        premium: true,
        type: 3
    },
    {
        value: 'background_striped_three',
        premium: true,
        type: 3
    },
    {
        value: 'background_striped_four',
        premium: true,
        type: 3
    },
    {
        value: 'background_striped_five',
        premium: true,
        type: 3
    },
    {
        value: 'background_striped_six',
        premium: true,
        isAppBackground: true
    },
    {
        value: 'background_striped_seven',
        premium: true,
        type: 3
    },
    {
        value: 'background_radial_one',
        type: 4
    },
    {
        value: 'background_radial_two',
        type: 4
    },
    {
        value: 'background_radial_three',
        type: 4
    },
    {
        value: 'background_radial_four',
        type: 4
    },
    {
        value: 'background_radial_five',
        premium: true,
        type: 4
    },
    {
        value: 'background_radial_six',
        premium: true,
        type: 4
    },
    {
        value: 'background_radial_seven',
        premium: true,
        type: 4
    },
    {
        value: 'background_radial_eight',
        premium: true,
        type: 4
    },
    {
        value: 'background_radial_nine',
        premium: true,
        type: 4
    },
    {
        value: 'background_radial_ten',
        premium: true,
        type: 4
    },
    {
        value: 'background_radial_eleven',
        premium: true,
        type: 4
    },
    {
        value: 'background_radial_twelve',
        premium: true,
        type: 4
    },
    {
        value: 'background_radial_thirteen',
        premium: true,
        isAppBackground: true,
        type: 4
    },
    {
        value: 'background_radial_fourteen',
        premium: true,
        type: 4
    },
    {
        value: 'background_radial_fifteen',
        premium: true,
        type: 4
    },
    {
        value: 'background_radial_sixteen',
        premium: true,
        type: 4
    },
    {
        value: 'background_radial_seventeen',
        premium: true,
        type: 4
    },
    {
        value: 'background_radial_eighteen',
        premium: true,
        type: 4
    },
    {
        value: 'background_radial_ninteen',
        premium: true,
        type: 4
    },
    {
        value: 'background_radial_twenty',
        premium: true,
        isAppBackground: true,
        type: 4
    },
    {
        value: 'background_repeat_linear_one ',
        type: 5
    },
    {
        value: 'background_repeat_linear_two',
        type: 5
    },
    {
        value: 'background_repeat_linear_three',
        type: 5
    },
    {
        value: 'background_repeat_linear_four',
        type: 5
    },
    {
        value: 'background_repeat_linear_five',
        premium: true,
        type: 5
    },
    {
        value: 'background_repeat_linear_six',
        premium: true,
        type: 5
    },
    {
        value: 'background_repeat_linear_seven',
        premium: true,
        type: 5
    },
    {
        value: 'background_repeat_linear_eight',
        premium: true,
        type: 5
    },
    {
        value: 'background_repeat_linear_nine',
        premium: true,
        type: 5
    },
    {
        value: 'background_repeat_linear_ten',
        premium: true,
        type: 5
    },
    {
        value: 'background_repeat_linear_eleven',
        premium: true,
        type: 5
    },
    {
        value: 'background_repeat_linear_twelve',
        premium: true,
        type: 5
    },
    {
        value: 'background_repeat_linear_thirteen',
        premium: true,
        type: 5
    },
    {
        value: 'background_repeat_linear_fourteen',
        premium: true,
        type: 5
    },
    {
        value: 'background_repeat_linear_fifteen',
        premium: true,
        type: 5
    },
    {
        value: 'background_repeat_linear_sixteen',
        premium: true,
        type: 5
    },
    {
        value: 'background_repeat_linear_seventeen',
        premium: true,
        type: 5
    },
    {
        value: 'background_repeat_linear_eighteen',
        premium: true,
        type: 5
    },
    {
        value: 'background_repeat_linear_ninteen',
        premium: true,
        type: 5
    },
    {
        value: 'background_repeat_linear_twenty',
        premium: true,
        type: 5
    },
    {
        value: 'background_repeat_linear_twentyone',
        premium: true,
        type: 5
    },
    {
        value: 'background_repeat_radial_one',
        type: 6
    },
    {
        value: 'background_repeat_radial_two',
        type: 6
    },
    {
        value: 'background_repeat_radial_three',
        type: 6
    },
    {
        value: 'background_repeat_radial_four',
        type: 6
    },
    {
        value: 'background_repeat_radial_five',
        premium: true,
        type: 6
    },
    {
        value: 'background_repeat_radial_six',
        premium: true,
        type: 6
    },
    {
        value: 'background_repeat_radial_seven',
        premium: true,
        type: 6
    },
    {
        value: 'background_repeat_radial_eight',
        premium: true,
        type: 6
    },
    {
        value: 'background_repeat_radial_nine',
        premium: true,
        type: 6
    },
    {
        value: 'background_repeat_radial_ten',
        premium: true,
        type: 6
    },
    {
        value: 'background_repeat_radial_eleven',
        premium: true,
        type: 6
    },
    {
        value: 'background_repeat_radial_twelve',
        premium: true,
        type: 6
    },
    {
        value: 'background_repeat_radial_thirteen',
        premium: true,
        type: 6
    },
    {
        value: 'background_repeat_radial_fourteen',
        premium: true,
        type: 6
    },
    {
        value: 'background_repeat_radial_fifteen',
        premium: true,
        type: 6
    },
    {
        value: 'background_repeat_radial_sixteen',
        premium: true,
        type: 6
    },
    {
        value: 'background_repeat_radial_seventeen',
        premium: true,
        type: 6
    },
    {
        value: 'background_repeat_radial_eighteen',
        premium: true,
        type: 6
    },
    {
        value: 'background_repeat_radial_ninteen',
        premium: true,
        type: 6
    },
    {
        value: 'background_repeat_radial_twenty',
        premium: true,
        type: 6
    },
    {
        value: 'background_repeat_radial_twentyone',
        premium: true,
        type: 6
    },
    {
        value: 'background_multi_gradient_linear_one',
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_two',
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_three',
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_four',
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_five',
        premium: true,
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_six',
        premium: true,
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_seven',
        premium: true,
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_eight',
        premium: true,
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_nine',
        premium: true,
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_ten',
        premium: true,
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_eleven',
        premium: true,
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_twelve',
        premium: true,
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_thirteen',
        premium: true,
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_fourteen',
        premium: true,
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_fifteen',
        premium: true,
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_sixteen',
        premium: true,
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_seventeen',
        premium: true,
        type: 7
    },
    {
        value: 'background_repeat_radial_eighteen',
        premium: true,
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_ninteen',
        premium: true,
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_twenty',
        premium: true,
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_twentyone',
        premium: true,
        type: 7
    },
    {
        value: 'background_multi_gradient_linear_radial_one',
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_two',
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_three',
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_four',
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_five',
        premium: true,
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_six',
        premium: true,
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_seven',
        premium: true,
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_eight',
        premium: true,
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_nine',
        premium: true,
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_ten',
        premium: true,
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_eleven',
        premium: true,
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_twelve',
        premium: true,
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_thirteen',
        premium: true,
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_fourteen',
        premium: true,
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_fifteen',
        premium: true,
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_sixteen',
        premium: true,
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_seventeen',
        premium: true,
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_eighteen',
        premium: true,
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_ninteen',
        premium: true,
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_twenty',
        premium: true,
        type: 8
    },
    {
        value: 'background_multi_gradient_linear_radial_twentyone',
        premium: true,
        type: 8
    },
    {
        value: 'background_conical_one',
        premium: true,
        type: 9
    },
    {
        value: 'background_pie_one',
        premium: true,
        type: 9
    },
    {
        value: 'background_pie_two',
        premium: true,
        type: 9
    },
]);

export const THEME_PROFILE_TYPES = {
    type1: 'icon',
    type2: 'card',
    type3: 'tile',
};
