import Typed from "typed.js";
import { useEffect, useRef } from "react";

type Props = {
    text?: string
}
const TypingText = ({ text = 'Experiment' }: Props) => {
    const el = useRef(null);

    useEffect(() => {
        const typed = new Typed(el.current, {
            strings: [text], // Strings to display
            startDelay: 400,
            typeSpeed: 100,
            backSpeed: 100,
            backDelay: 100
        });

        // Destropying
        return () => {
            typed.destroy();
        };
    }, [text]);

    return (<span className="d-flex"><span ref={el}></span></span>);
}

export default TypingText;
