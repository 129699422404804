import React, { Fragment } from "react";
import CustomInput from ".";

const DynamicInputs = ({ formData, data, onChangeEvent }: any) => {
  return (
    <Fragment>
      {formData.map((obj: any, i: number) => {
        return (
          <CustomInput
            key={'card__' + obj?.keyname + '__' + i}
            {...obj}
            value={data?.[obj?.keyname]?.value}
            error={data?.[obj?.keyname]?.error}
            onChangeEvent={onChangeEvent}
          />
        )
      })}
    </Fragment>
  )
}

export default DynamicInputs;
