import React from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from '../../../utility/constant';

const TitleUpdate = ({ title = '' }) => {
  return (
    <Helmet>
      <title>{APP_NAME}{title ? `-${title}`: ''}</title>
    </Helmet>
  )
};

export default TitleUpdate;
