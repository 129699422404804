import { Col, Form } from "../../Bootstrap";

type Props = {
    type?: string,
    keyname: string,
    value?: string | number,
    error?: string,
    placeholder?: string,
    onBlurEvent?: any,
    isRequired?: boolean,
    onKeyEvent?: any,
    onChangeEvent?: any,
    isTextarea?: boolean,
    col?: number,
    elementRef?: any,
    isDisabled?: boolean,
    additionalClass?: string,
    isSetValueRequired?: boolean,
    isSetDefaultValueRequired?: boolean
}

const CustomInput = ({
    type = 'text',
    keyname,
    value = '',
    error = '',
    placeholder,
    onChangeEvent,
    onBlurEvent,
    isRequired = false,
    onKeyEvent,
    isTextarea = false,
    col = 4,
    elementRef = null,
    isDisabled = false,
    additionalClass = '',
    isSetValueRequired = false,
    isSetDefaultValueRequired = true
}: Props) => {
    const elementKeyBase = keyname + '__';
    const defaultValueRequired = isSetValueRequired ? false : isSetDefaultValueRequired;
    return (
        <Col key={elementKeyBase + 'container'} md={col} className={`customInputWrapper ${additionalClass}`}>
            <Form.Group key={elementKeyBase + 'wrapper'} className={`input-layer m-0 ${isTextarea ? 'textarea' : ''}`} >
                <Form.Control
                    key={elementKeyBase + 'input'}
                    {...(elementRef && { ref: elementRef })}
                    type={type}
                    name={keyname}
                    placeholder={`${isRequired ? '*' : ''} ${placeholder}`}
                    {...(isSetValueRequired && { value })}
                    {...(defaultValueRequired && { defaultValue: value ? value : '' })}
                    {...(onChangeEvent && { onChange: onChangeEvent })}
                    {...(onKeyEvent && { onKeyUp: onKeyEvent })}
                    {...(onBlurEvent && { onBlur: onBlurEvent })}
                    className={`${error && "is-invalid"}`}
                    as={isTextarea ? 'textarea' : 'input'}
                    {...(isTextarea && { rows: 5 })}
                    disabled={isDisabled}
                />
                <label key={elementKeyBase + 'label'} className='input-label' >
                    {isRequired && <span className='important'>* </span>}
                    {placeholder}
                </label>
            </Form.Group>
            {error && <span className="invalid-custom-input">{error}</span>}
        </Col>
    )
};

export default CustomInput;
