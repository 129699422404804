import { decryptData, encryptData } from "./encrypt";

const setLocalStorageData = (key: string, value: any): void => {
  try {
    const encryptedValue = value ? encryptData(value) : '';
    localStorage.setItem(key, encryptedValue);
  } catch {

  }
};

const getLocalStorageData = async (key: string) => {
  try {
    const data = localStorage.getItem(key);
    const decrytedData = data ? await decryptData(data) : '';
    return decrytedData;
  } catch {
    return '';
  }
};

const clearLocalStorageData = (): any => localStorage.clear();

const setSessionStorageData = (key: string, value: any): void => {
  try {
    const encryptedValue = value ? encryptData(value) : '';
    sessionStorage.setItem(key, encryptedValue);
  } catch {
  }
};

const getSessionStorageData = async (key: string) => {
  try {
    const data = sessionStorage.getItem(key);
    const decrytedData = data ? await decryptData(data) : '';
    return decrytedData;
  } catch {
    return '';
  }

};

const removeSessionStorageDate = (key: string) => sessionStorage.removeItem(key);

const clearSessionStorageData = (): any => sessionStorage.clear();

export {
  setLocalStorageData,
  getLocalStorageData,
  clearLocalStorageData,
  setSessionStorageData,
  getSessionStorageData,
  removeSessionStorageDate,
  clearSessionStorageData
}
