import React, { Fragment, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ROUTES } from '../utility/constant';
import { useStateValue } from '../Context/Context';
import { Loader } from '../Shared/Elements';

const CallingCardComponent = lazy(() => import("../Components/CallingCard"));
const LoginComponent = lazy(() => import("../Components/Login"));
const CallingCardListComponent = lazy(() => import("../Components/CallingCardList"));
const CallingCardFormComponent = lazy(() => import("../Components/CallingCardForm"));
const ProfileLinkFormComponent = lazy(() => import("../Components/ProfileLinksForm"));
const UserComponent = lazy(() => import("../Components/UserList"));
const LandingComponent = lazy(() => import('../Components/Landing'));
const ResetPasswordComponent = lazy(() => import('../Components/ResetPassword'));
const PaymentListComponent = lazy(() => import('../Components/PaymentList'));
const WebsiteComponent = lazy(() => import('../Components/Website'));
const EnquiryListComponent = lazy(() => import('../Components/Enquiry'));
const EmailVerification = lazy(() => import('../Components/EmailVerification'));
const ServiceDeskComponent = lazy(() => import('../Components/ServiceDesk'));
const ServiceListComponent = lazy(() => import('../Components/ServiceDesk/ServiceList'));

const WrapperComponent = ({ Element }: any) => (
  <Suspense fallback={<Loader additionalStyle={`app-loader`} />}>
    <Element />
  </Suspense>
);

function AppRoutes() {
  const [{ app }] = useStateValue();
  const { admin, orgAdmin } = app?.data;
  return (
    <Router>
      <Routes>
        <Route path="/" element={<WrapperComponent Element={WebsiteComponent} />} />
        <Route path={ROUTES.website} element={<WrapperComponent Element={WebsiteComponent} />} />
        <Route path={ROUTES.loading} element={<WrapperComponent Element={LandingComponent} />} />
        <Route path={ROUTES.login} element={<WrapperComponent Element={LoginComponent} />} />
        <Route path={ROUTES.emailVerification(':id')} element={<WrapperComponent Element={EmailVerification} />} />
        <Route path={ROUTES.resetPassword} element={<WrapperComponent Element={ResetPasswordComponent} />} />
        <Route path={ROUTES.cardList} element={<WrapperComponent Element={CallingCardListComponent} />} />
        <Route path={ROUTES.cardEdit(':id')} element={<WrapperComponent Element={CallingCardFormComponent} />} />
        <Route path={ROUTES.linkCardEdit(':id')} element={<WrapperComponent Element={ProfileLinkFormComponent} />} />
        <Route path={ROUTES.profile(':id')} element={<WrapperComponent Element={CallingCardComponent} />} />
        <Route path={ROUTES.enquiry(':id', ':name')} element={<WrapperComponent Element={EnquiryListComponent} />} />
        <Route path={ROUTES.serviceDesk} element={<WrapperComponent Element={ServiceDeskComponent} />} />
        {
          (admin || orgAdmin) ? <Fragment>
            <Route path={ROUTES.user} element={<WrapperComponent Element={UserComponent} />} />
          </Fragment> : null
        }
        {
          admin ? <Fragment>
            <Route path={ROUTES.cardAdd} element={<WrapperComponent Element={CallingCardFormComponent} />} />
            <Route path={ROUTES.linkCardAdd} element={<WrapperComponent Element={ProfileLinkFormComponent} />} />
            <Route path={ROUTES.payment(':id', ':name')} element={<WrapperComponent Element={PaymentListComponent} />} />
            <Route path={ROUTES.serviceDeskList} element={<WrapperComponent Element={ServiceListComponent} />} />
          </Fragment> : null
        }
        <Route path="*" element={<WrapperComponent Element={WebsiteComponent} />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
