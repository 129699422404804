import CryptoJS from "crypto-js";

export const encryptData = (value: any): string => {
  const ciphertext = CryptoJS.AES.encrypt(value, 'secret key 123').toString();
  return ciphertext;
};

export const decryptData = (value: any): string => {
  const data  = CryptoJS.AES.decrypt(value, 'secret key 123');
  const originalText = data.toString(CryptoJS.enc.Utf8);  
  return originalText;
};
