import { AOS_ANIMATIONS, APP_CARD, PAGE_LIMIT, PAGE_LIMIT_MAX, ROUTES, SERVICE_DESK_STATUS } from "./constant";

const appendZero = (value: any) => {
    let result = value.toString();
    if (result.length === 1) {
        result = "0" + result;
    }
    return result;
}

export const getLocalDate = (date: Date) => {
    if (!date) return date;
    const d = new Date(date);
    return d.getFullYear() + '-' + appendZero(d.getMonth() + 1) + '-' + appendZero(d.getDate()) || '';
};

export const addDaysToDate = (date: any = null, DaysToAdd = 364) => {
    const d = date ? new Date(date): new Date();
    return new Date(d.setDate(d.getDate() + DaysToAdd));
};

export const getUTCDate = (date: any) => {
    if (!date) return date;
    const d = new Date(date);
    const offsetDate = new Date(d.setUTCHours(23, 59, 59, 0) + (d.getTimezoneOffset() * 60000));
    const dateUtc = new Date(Date.UTC(offsetDate.getUTCFullYear(), offsetDate.getUTCMonth(), offsetDate.getUTCDate(), offsetDate.getUTCHours(), offsetDate.getUTCMinutes(), offsetDate.getUTCSeconds(), 0));
    return dateUtc;
};

export const getUTCTimestamp = (date = null) => {
    const d = date ? date : new Date();
    var UTCseconds = d.getTime() + d.getTimezoneOffset() * 60 * 1000;
    return UTCseconds;
};

export const isValidString = (value: any) => {
    return Boolean(value)
};

export const isValidData = (data: Array<any>, properties: any = 'value') => {
    return (Array.isArray(data) && data?.length !== 0 && data?.some((obj: any) => isValidString(obj?.[properties])));
};

export const capitalizeFirstLetter = (text: string) => {
    return text ? text.charAt(0).toUpperCase() + text.slice(1) : text;
};

export const isImgUrl = (url: string) => {
    const img = new Image();
    img.src = url;
    return new Promise((resolve) => {
        img.onerror = () => resolve(false);
        img.onload = () => resolve(true);
    });
};

export const goToCompanyProfileCard = () => {
    const url = window.location.origin + ROUTES.profile(APP_CARD);
    window.open(url, '_blank');
};

export const getPainationLimit = () => {
    return window.innerWidth < 700 ? PAGE_LIMIT : PAGE_LIMIT_MAX;
};

export const loadAOSAnimation = (type = AOS_ANIMATIONS.fadeUp, options: any = {}) => {
    const { isPreview = false, timeDelay = 100 } = options;
    if (isPreview) return ({ "data-aos": null, "data-aos-delay": 0 });
    return { "data-aos": type, "data-aos-delay": timeDelay }
};

export const throttle = (func: any, limit: number | undefined) => {
    let inThrottle = false;
    return function (this: any) {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
            func.apply(context, args);
            inThrottle = true;
            setTimeout(() => inThrottle = false, limit);
        }
    }
};

export const getServiceStatus = (value: number) => {
    switch (value) {
        case SERVICE_DESK_STATUS.pending:
            return 'Pending';
        case SERVICE_DESK_STATUS.assigned:
            return 'Assigned';
        case SERVICE_DESK_STATUS.resolved:
            return 'Resolved';
        case SERVICE_DESK_STATUS.hold:
            return 'Hold';
        default:
            return 'Pending';
    }
};
